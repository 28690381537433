//
// Frameworks
//
import Rails from "@rails/ujs";
// 本番だと、@rails/ujs のstart()がどこかで実行されているので、既に呼ばれていたらスキップする
if (window._rails_loaded === undefined) {
  Rails.start();
}
import $ from "jquery";
window.$ = window.JQuery = $;
